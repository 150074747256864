th {
  font-size: 1.2em;
  font-weight: 600;
  color: #faf8f8;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff !important;
  min-width: 100%;
  max-width: 150%;
}

tr {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 15px;
}

.table-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  width: 100%;
}

.no-data {
  padding: 8px;
  text-align: center !important;
  margin-left: auto;
  margin-right: auto;
}

#change {
  width: 40% !important;
}

#regulator,
#date,
#jurisdiction,
#impact {
  width: 10% !important;
}

#type {
  width: 15% !important;
}

#status {
  width: 5% !important;
}

.table-style {
  background-color: tableBg;
  color: tableColor;
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  padding: 8px;
}

.change-header {
  display: flex !important;
  align-items: center !important;
  max-width: 30vw !important;
}

.change-data {
  display: flex !important;
  align-items: center !important;
  position: relative;
}

.spinner-style {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  display: flex !important;
}

.filter-button {
  background: "none";
  border: "none";
  color: headerColor;
  cursor: "pointer";
}

.link-icon {
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  width: 1rem;
  height: 1rem;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .table-container {
    overflow-x: scroll;
  }

  th,
  td {
    white-space: nowrap;
  }

  #change {
    width: 20% !important;
  }

  #regulator,
  #date,
  #jurisdiction,
  #impact {
    width: 20% !important;
  }

  #type {
    width: 20% !important;
  }

  .change-header {
    max-width: 600px !important;
    min-width: 300px !important;
    white-space: normal !important;
  }

  .link-icon {
    right: -6px;
  }
}

.pulse {
  animation: pulse 1s infinite;
}

.pointer {
  cursor: pointer;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.border-light {
  border-bottom: 1px solid gray;
}

.border-dark {
  border-bottom: 1px solid navy;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #0b1437;
  z-index: 1;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.table-row-height {
  height: 50px;
}

.table-data {
  padding: 8px;
}