.top-margin-100 {
  margin-top: 110px;
}

@media (max-width: 600px) {
  .top-margin-100 {
    margin-top: 50px;
  }
}

.footer-custom-style {
  position: relative;
  top: 82vh;
}