/* chat.css */
@media screen and (max-width: 768px) {
  .markdown-content ul {
    margin-left: 20px; /* Adjust as needed */
  }
  .markdown-content ol {
    margin-left: 20px; /* Adjust as needed */
  }
  .markdown-content a {
    color: #007bff;
  }
}

@media screen and (min-width: 768px) {
  .markdown-content ul {
    margin-left: 20px; /* Adjust as needed */
  }
  .markdown-content ol {
    margin-left: 40px; /* Adjust as needed */
  }
  .markdown-content a {
    color: #007bff;
  }
}

@media screen and (min-width: 380px) {
  .css-1v4zv6g {
    width: 95% !important;
  }
}

.chat-section {
  margin-top: '40px';
  position: 'relative';
  background-color: transparent;
}

.dragging {
  background-color: rgba(0, 0, 0, 0.2) !important;
}