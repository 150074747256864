.notification-nav-bar {
    margin-left: auto;
}

@media (max-width: 1200px) {
    .notification-nav-bar {
        margin-left: auto;
        margin-right: 50px;
        margin-top: -33px;
        position: relative;
    }
}

