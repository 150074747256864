.carousel .carousel-caption {
    background: rgba(0, 0, 0, .5);
    padding: 1rem 1rem;
    width: 70vw;
    margin: 0 auto;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 25%;
    left: 15%;
    z-index: 10;

    color: #fff;
    text-align: center;
}

.carousel a {
    background: rgba(0, 0, 0, 1);
  color: var(--mastColor);

}

#carousel {
    border-top: 5px #fff solid;
    border-bottom: 5px #fff solid;
       min-height: calc(15vh + 90px);

   
    /*  height: minmax(30vh 40vh);*/
}

#carousel img {
    object-position: top;
    width: 100vw;
    min-height: calc(15vh + 90px);
    max-height: 20vh;
    display: block;
    object-fit: cover;
}

#contact .fas {
    margin-bottom: 0rem;
    color: #ffffff;
}

@media (min-width: 576px) {
    .container {
        max-width: 97vw;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 90vw;
    }

}

@media (min-width: 992px) {
    .container {
        max-width: 80vw;
    }
    
    

}
    #value_prop_leadin {
    padding-top: 2rem;;
        padding-bottom: 0rem;;
}


@media (min-width: 1200px) {
    .container {
        max-width: 70vw;
    }

    .carousel .carousel-caption {
        width: 70vw;
    }
}

@media (min-width: 1500px) {
    .container {
        max-width: 60vw;
    }

    .carousel .carousel-caption {
        width: 60vw;

    }
}

@media (min-width: 1800px) {
    .container {
        max-width: 55vw;
    }

    .carousel .carousel-caption {

        width: 55vw;

    }
}

#cover .container {
    padding-left: 0px;
}

h4 {
    font-size: 1.5em !important;
}

.text-light {
    font-size: larger;
}

.about-text {
    padding-top: 55px;
}

.margin-right-4 {

    margin-right: 4px;

}